/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */

/**
 * The Global Video component powered by Orca player
 *
 * Can be used as article body element or lead art
 *
 * @param String uuid video id
 * @param Object videoConfig contains shared video classes and caption info
 * @returns {node} Orca player component
 */
import React from "react";
import PropTypes from "prop-types";
import { useAppContext } from "fusion-context";
import WapoLazyChild from "~/shared-components/WapoLazyChild";
import { isSubscriber } from "~/components/utilities/login-details";
import VideoWrapper from "./wrapper";
import VideoElement from "./video";

const Video = (props) => {
  const {
    videoConfig: {
      ads,
      aspectRatio,
      autoinit,
      autoplay,
      className,
      customBtnText,
      featureName,
      forcePlay,
      includeDurationOrLive,
      isAdmin,
      isLive,
      isLooping,
      isLoopingRaw,
      loadByFeature,
      muted,
      playthrough,
      showBlurb,
      showPreview,
      showPreview2,
      showTitle,
      playerType
    },
    unresizedAlternateArt,
    alternateArt,
    index,
    videoData,
    uuid,
    disableLazyLoading
  } = props;

  const videoElementProps = {
    isSubscriber,
    videoData,
    index,
    featureName,
    loadByFeature,
    ads,
    aspectRatio,
    autoinit: isAdmin ? false : !!autoinit || false,
    autoplay: isAdmin ? false : !!autoplay || false,
    blurb: showBlurb || showBlurb === undefined || false,
    durationOrLive:
      includeDurationOrLive || includeDurationOrLive === undefined || false,
    unresizedAlternateArt,
    alternateArt,
    customBtnText,
    isAdmin,
    isLive,
    isLooping,
    isLoopingRaw,
    muted,
    playthrough,
    showPreview,
    showPreview2,
    showTitle,
    uuid: uuid || (videoData && videoData._id),
    forcePlay,
    playerType
  };

  // check if we're using exproxy
  const { siteProperties } = useAppContext();

  const adminMsg =
    isAdmin && isLoopingRaw ? (
      <div
        style={{
          fontSize: "0.75rem",
          lineHeight: 0.75,
          whiteSpace: "nowrap",
          position: "absolute",
          zIndex: 2,
          padding: "3px",
          backgroundColor: "yellow",
          opacity: 0.66
        }}
      >
        looping video
      </div>
    ) : null;

  const eager =
    disableLazyLoading || siteProperties.disableLazyLoading || false;

  return (
    <WapoLazyChild
      eager={eager}
      offsetTop={300}
      offsetBottom={300}
      throttle={25}
      renderPlaceholder={(ref) => (
        <div ref={ref}>
          <VideoWrapper
            wrapperRef={ref}
            className={className}
            alternateArt={alternateArt}
            adminMsg={adminMsg}
          />
        </div>
      )}
    >
      <VideoWrapper
        className={className}
        alternateArt={alternateArt}
        adminMsg={adminMsg}
      >
        {((videoData && videoData._id) || uuid) && (
          <VideoElement index={index} {...videoElementProps} />
        )}
      </VideoWrapper>
    </WapoLazyChild>
  );
};

Video.propTypes = {
  unresizedAlternateArt: PropTypes.string,
  alternateArt: PropTypes.string,
  customBtnText: PropTypes.string,
  featureName: PropTypes.string,
  index: PropTypes.number,
  playlistClicked: PropTypes.bool,
  videoConfig: PropTypes.shape({
    ads: PropTypes.bool,
    aspectRatio: PropTypes.number,
    className: PropTypes.string,
    captionClassName: PropTypes.string,
    caption: PropTypes.string,
    forcePlay: PropTypes.bool,
    includeDurationOrLive: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isLive: PropTypes.bool,
    isLooping: PropTypes.bool,
    isLoopingRaw: PropTypes.bool,
    loadByFeature: PropTypes.bool,
    muted: PropTypes.bool,
    playthrough: PropTypes.bool,
    showBlurb: PropTypes.bool,
    showTitle: PropTypes.bool,
    playerType: PropTypes.string
  }),
  videoData: PropTypes.object
};

Video.defaultProps = {
  featureName: "null"
};

export default Video;
