import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import { useChainContext } from "~/shared-components/ChainContext";
import { getActiveBreakpoints } from "~/shared-components/chains/top-table/utilities/data-transformation";

const defaultIcon = "⚠";

const wrapperStyle = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: "rgba(234, 0, 23, .1)",
  pointerEvents: "none"
};

const Warning = ({ message, level, icon, wrapperOn }) => {
  if (!message) return null;

  let color;
  let bg;
  let classNames = "font-xs";
  let style = { position: "relative", top: "2px" };

  switch (level) {
    case "info":
      if (icon === defaultIcon) {
        icon = `ⓘ`;
        classNames = "font-xxxs";
        style = {
          fontFamily: "serif",
          fontWeight: "bold"
        };
      }
      color = "inherit";
      bg = "#eee";
      break;
    case "warning":
      color = "black";
      bg = "yellow";
      break;
    default:
      color = "white";
      bg = "#ea0017"; // red
      break;
  }

  const warning = (
    <div
      className="pr-xxs pb-xxs pl-xxs font-xxxs lh-1"
      style={{ color, backgroundColor: bg }}
    >
      <span className={`pr-xxs ${classNames}`} style={style}>
        {icon}
      </span>
      {message}
    </div>
  );

  return (
    <Fragment>
      {wrapperOn && <div className={"absolute z-2"} style={wrapperStyle} />}
      {warning}
    </Fragment>
  );
};

export default Warning;

Warning.propTypes = {
  message: PropTypes.any,
  level: PropTypes.oneOf(["info", "warning", "error"]),
  icon: PropTypes.string,
  wrapperOn: PropTypes.bool
};

Warning.defaultProps = {
  level: "error",
  icon: defaultIcon,
  wrapperOn: false
};

const meetsColumnRequirements = ({ cols = {}, dataSpans, bps = [] }) => {
  let message = !dataSpans ? "No colspans specified" : "";
  if (message)
    return {
      meetsRequirements: !message,
      message
    };
  const messages = [];

  const min = Number.parseInt(cols?.min, 10);
  const checkMin = !Number.isNaN(min);
  const failMin = [];
  const max = Number.parseInt(cols?.max, 10);
  const checkMax = !Number.isNaN(max);
  const failMax = [];

  if (checkMin || checkMax) {
    bps.forEach((bp) => {
      const key = `data-${bp}-span`;
      const span = dataSpans[key];
      if (checkMin && span < min) failMin.push(`${bp}: ${span}`);
      if (checkMax && span > max) failMax.push(`${bp}: ${span}`);
    });
  }
  if (failMin.length)
    messages.push(`MIN of ${min} cols required: ${failMin.join(", ")}`);
  if (failMax.length)
    messages.push(`MAX of ${max} cols required: ${failMax.join(", ")}`);

  message = messages.length ? messages.join(" / ") : "";

  return {
    meetsRequirements: !message,
    message
  };
};

/*
 * @param {string} featureLabel - optional. Will be used in the warning message. Defaults to "This feature"
 * @param {object} placementRequirements - optional. A list of canned requirements. The two supported now are
 *   inMultiTableChain: true and isFullSpanLayout: true. This can be extended. This option is *not* intended to
 *   be used with the placementWarning option below.
 * @param {string} placementWarning - optional. A string that will appear in the warning.
 *
 * @returns {object} - A <Warning /> component
 */
export const IllegalPlacementWarning = ({
  featureLabel,
  placementRequirements,
  placementWarning,
  overrides,
  dataSpans
}) => {
  const { hideFromWeb, hideFromApps, isUnassigned, layout } =
    useChainContext() || {};
  const { layoutName, section, tableKey } = useSandwichLayoutContext() || {};

  const contentService = overrides?.flexFeatureContentConfig?.contentService;

  // NOTE: isUnassinged will be true when a feature is in a Multi-table chain but not due to show b/c
  // it was ot assigned to a table. Therefore, something unassigned cannot be illegally placed.
  if (isUnassigned) return null;

  let isIllegalPlacement = false;
  if (placementRequirements) {
    // inMultiTableChain
    if (
      !isIllegalPlacement &&
      placementRequirements.inMultiTableChain &&
      layout === undefined
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} must be placed in a Multi-Table Chain. Failing to do so could lead to severe rendering problems.`;
    }
    // tableKeys
    if (
      !isIllegalPlacement &&
      placementRequirements.tables &&
      !placementRequirements.tables.includes(tableKey)
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} must be placed in one of these tables: ${placementRequirements.tables
        .toString()
        .replace(/,/g, ", ")}.`;
    }
    // illegalContent
    if (
      !isIllegalPlacement &&
      placementRequirements.illegalContent &&
      placementRequirements.illegalContent.includes(contentService)
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} cannot contain ${contentService}.`;
    }
    // isFullSpanLayout
    if (
      !isIllegalPlacement &&
      placementRequirements.isFullSpanLayout &&
      layout !== "Full span"
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} must be placed in a Multi-table Chain with 'Full span' layout. Failing to do so could lead to severe rendering problems.`;
    }
    // cols
    if (!isIllegalPlacement && placementRequirements?.cols) {
      const { meetsRequirements, message } = meetsColumnRequirements({
        cols: placementRequirements.cols,
        dataSpans,
        bps: getActiveBreakpoints({
          layoutName,
          customFields: overrides
        }).filter((bp) => !/xs/.test(bp))
      });
      if (!meetsRequirements) {
        isIllegalPlacement = true;
        placementWarning = `${featureLabel} placement does not meet column requirements: ${message}`;
      }
    }
    // inZone
    if (
      !isIllegalPlacement &&
      placementRequirements.inZone &&
      !placementRequirements.inZone.includes(section)
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} should not be placed in this zone. Doing so will lead to severe rendering problems. Please move it into the Content zone.`;
    }
    // inAppOnly
    if (
      !isIllegalPlacement &&
      placementRequirements.inAppOnly &&
      !hideFromWeb
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} should not be in a chain shown on web. Doing so will lead to severe rendering problems. Hide the chain on web or remove the feature.`;
    }
    // inWebOnly
    if (
      !isIllegalPlacement &&
      placementRequirements.inWebOnly &&
      !hideFromApps
    ) {
      isIllegalPlacement = true;
      placementWarning = `${featureLabel} should not be in a chain shown on apps. Doing so will lead to severe rendering problems. Hide the chain on apps or remove the feature.`;
    }
  }

  const showWarning =
    (placementRequirements && isIllegalPlacement) ||
    (!placementRequirements && placementWarning);

  if (!showWarning) return null;

  return <Warning message={placementWarning} wrapperOn={true} />;
};

IllegalPlacementWarning.propTypes = {
  featureLabel: PropTypes.string,
  placementRequirements: PropTypes.object,
  placementWarning: PropTypes.string,
  overrides: PropTypes.object,
  dataSpans: PropTypes.object
};

IllegalPlacementWarning.defaultProps = {
  featureLabel: "This feature",
  placementWarning:
    "This feature cannot be placed here. Keeping it here could lead to severe rendering problems."
};
