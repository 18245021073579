import React from "react";
import PropTypes from "prop-types";

const VIDEO_SLATE =
  "https://www.washingtonpost.com/player/prod/img/wp_grey.jpg";

const getPlaceholderStyle = (url = VIDEO_SLATE) => {
  return {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundColor: "rgb(245, 245, 245)",
    backgroundImage: `url('${url}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  };
};

const VideoWrapper = ({ className, children, alternateArt, adminMsg }) => {
  const [style, setStyle] = React.useState(getPlaceholderStyle());

  React.useEffect(() => {
    if (alternateArt) {
      setStyle(getPlaceholderStyle(alternateArt));
    }
  }, [alternateArt]);

  return (
    <figure className={className}>
      {adminMsg}
      <div className="video-wrapper" style={style}>
        {children}
      </div>
    </figure>
  );
};

VideoWrapper.propTypes = {
  ref: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
  alternateArt: PropTypes.string,
  adminMsg: PropTypes.any
};

export default VideoWrapper;
